import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import "@fontsource/montserrat"
import {
    heroContainer,
    heroImage,
    heroItems
} from'./lib/hero.module.css'


const BespokeHero = () => {
    return (
        <div className={heroContainer}>
            <StaticImage
                className={heroImage}
                layout="fullWidth"
                alt="Bespoke Services"
                src={"../../images/bespoke/hero.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
            />
            <div className={heroItems}>
                <div>
                    <h1>Bespoke</h1>
                    <p>Services</p>
                    <p style={{fontSize: '0.6rem'}}>Photographer: Ryan Goff | Interior Designer: Holly Keeling</p> 
                </div>
            </div>
        </div>
    )
  }
  
export default BespokeHero