import * as React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from '../components/layout'
import BespokeHero from '../components/hero/bespoke-hero'
import TwoColumns from '../components/twocolumns'
import "@fontsource/montserrat"
import {
  buttonsContainer
} from'../lib/buttons.module.css'

const BespokePage = ({data}) => {
  return (
    <Layout pageTitle="Bespoke">
      <BespokeHero />
      <div className={buttonsContainer}>
        <div><Link to="/collections">Collections</Link></div>
        <div><AnchorLink to="#contact" title="Get in touch">Get in touch</AnchorLink></div>
      </div>
      <TwoColumns content={data.allPagesJson} />
    </Layout>
  )
}

export const query = graphql`
  query BespokePageQuery {
    allPagesJson(filter: {title: {in: ["Bay Poles", "Tracked and Corded", "Painted for You", "Electric"]}}) {
      edges {
        node {
          title
          text
          anchor
          pdfdownloadtext
          pdfdownload
          emailtext
          emailandsubject
          photo {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 80
                  blurredOptions: {width: 100}
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                  formats: WEBP
                  width: 960
                  layout: CONSTRAINED
                )
              }
          }
        }
      }
    }
  }
`

export default BespokePage